import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	membershipReminder: {
		data: {},
		isLoading: false,
		hasCompleted: false,
		error: undefined
	}
};

export const membershipReminderSlice = createSlice({
	name: 'membershipReminder',
	initialState,
	reducers: {
		membershipReminder: (state) => {
			state.membershipReminder.isLoading = true;
			state.membershipReminder.error = undefined;
		},
		membershipReminderCompleted: (state, action) => {
			state.membershipReminder.isLoading = false;
			state.membershipReminder.hasCompleted = true;
			state.membershipReminder.data = action.payload;
		},
		membershipReminderFailed: (state, action) => {
			state.membershipReminder.isLoading = false;
			state.membershipReminder.error = action.payload;
		},
		membershipReminderReset: (state) => {
			state.membershipReminder = initialState.membershipReminder;
		}
	}
});

export const {
	membershipReminder,
	membershipReminderCompleted,
	membershipReminderFailed,
	membershipReminderReset
} = membershipReminderSlice.actions;

export default membershipReminderSlice.reducer;
