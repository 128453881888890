import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import helperImage from '../../assets/sub-num-help.jpeg';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	padding: '25px'
}));

const Overview = ({ t }) => {
	const navigate = useNavigate();
	const [openSubNumberDialog, setOpenSubNumberDialog] = React.useState(false);

	const { data, hasCompleted } = useSelector(
		(state) => state.membershipReminderSlice.membershipReminder
	);

	React.useEffect(() => {
		if (!hasCompleted) {
			navigate('/home');
		}
	}, []);

	return (
		hasCompleted &&
		data && (
			<React.Fragment>
				<Typography
					component='h2'
					sx={{ fontSize: '2em', fontWeight: 'bold', marginBottom: '10px' }}
				>
					{t('home:welcomeHeader')}
				</Typography>
				<Typography component='p' sx={{ margin: '15px 0' }}>
					{t('home:welcomeText')}
				</Typography>
				<StyledBox component='section'>
					<Stack
						direction='row'
						flexWrap='wrap'
						justifyContent='space-between'
						spacing={2}
					>
						<Box>
							<Typography sx={{ textTransform: 'capitalize' }}>
								<Typography component='span'>Dear</Typography>
								<Typography
									component='span'
									sx={{
										ml: '5px',
										textTransform: 'capitalize',
										fontWeight: 'bold',
										display: 'inline-block'
									}}
								>
									{data?.subscriberRequest.firstName},
								</Typography>
							</Typography>
							<Typography>
								Your request to receive email billing reminders has been
								successfully submitted. Thank you for using Membership Reminder!
							</Typography>
							<List>
								<ListItem>
									Subscriber No.:{' '}
									<Typography
										ml={1}
										textTransform='capitalize'
										fontWeight='bold'
									>
										{data?.subscriberRequest.subscriberNumber}
									</Typography>
								</ListItem>
								<ListItem>
									First Name:{' '}
									<Typography
										ml={1}
										textTransform='capitalize'
										fontWeight='bold'
									>
										{data?.subscriberRequest.firstName}
									</Typography>
								</ListItem>
								<ListItem>
									Last Name:{' '}
									<Typography
										ml={1}
										textTransform='capitalize'
										fontWeight='bold'
									>
										{data?.subscriberRequest.lastName}
									</Typography>
								</ListItem>
								<ListItem>
									Email Address:{' '}
									<Typography ml={1} fontWeight='bold'>
										{data?.subscriberRequest.email}
									</Typography>
								</ListItem>
							</List>
						</Box>
						<Box>
							<Typography sx={{ fontSize: '0.7em' }}>
								<Trans i18nKey='home:disclosureText'>
									When you enroll in this service, we will email you a monthly
									billing reminder approximately 10 days before we automatically
									charge your credit or debit card for your membership. Please
									note that enrolling in this reminder service does not affect
									the terms of the offer(s) to which you already agreed. Even if
									you do not sign up for this reminder service, or if you do not
									receive a monthly billing reminder due to technical or other
									reasons, we will continue to charge the card we have on file
									as specified in the terms of the offer(s). Please allow 72
									hours before we process your request to receive reminders. It
									is possible that, within the 72 hour update period or in the
									last several days, a payment has been sent to our payment
									processor regarding your membership(s). If this has occurred,
									you will not receive a membership reminder email for that
									charge as the associated payment has already been initiated.
									Please add{' '}
									<Link to='mailto:membership@onlinemembershipservices.com'>
										membership@onlinemembershipservices.com
									</Link>{' '}
									to your approved email sender list to prevent your reminder
									emails from going to your spam folder. If your email changes
									in the future, just resubmit your information and we&apos;ll
									update our records to reflect the change. To update your
									contact information or for other inquiries, contact our
									customer service department at:{' '}
									<Link to='mailto:membership@onlinemembershipservices.com'>
										membership@onlinemembershipservices.com
									</Link>
									.
								</Trans>
							</Typography>
						</Box>
					</Stack>
				</StyledBox>
				<Dialog
					open={openSubNumberDialog}
					onClose={() => setOpenSubNumberDialog(false)}
					aria-labelledby='subscriber-number-help-title'
					aria-describedby='subscriber-number-help-description'
				>
					<DialogContent>
						<img src={helperImage} alt={t('home:subscriberHelperAltText')} />
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpenSubNumberDialog(false)}>
							{t('common:buttons.close')}
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		)
	);
};

Overview.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['home', 'common'])(Overview);
