import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import TextInput from '../../components/TextInput';
import CheckboxInput from '../../components/CheckboxInput';

import {
	membershipReminder,
	membershipReminderReset
} from '../../containers/MembershipReminder/membershipReminderSlice';

import helperImage from '../../assets/sub-num-help.jpeg';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

const StyledFormBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	padding: '25px'
}));

const StyledButton = styled(Button)(({ theme }) => ({
	width: 300,
	fontSize: '1.2em',
	fontWeight: 'bold',
	color: theme.palette.common.white
}));

const defaultValues = {
	subscriberNumber: '',
	firstName: '',
	lastName: '',
	emailAddress: '',
	confirmEmail: ''
};

const Home = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { handleSubmit, control, trigger, reset } = useForm({
		defaultValues
	});

	const [openSubNumberDialog, setOpenSubNumberDialog] = React.useState(false);
	const [emailMatch, setEmailMatch] = React.useState(true);

	const { error, data, hasCompleted } = useSelector(
		(state) => state.membershipReminderSlice.membershipReminder
	);

	React.useEffect(() => {
		reset();
		dispatch(membershipReminderReset());
	}, []);

	React.useEffect(() => {
		if (!error && data?.response && hasCompleted) {
			navigate('/overview');
		}
	}, [data, error, hasCompleted]);

	const validateField = async (fieldName) => {
		try {
			await trigger(fieldName);
		} catch (err) {
			console.log('err', err);
		}
	};

	const onSubmit = (data, e) => {
		e.preventDefault();
		if (data.emailAddress === data.confirmEmail) {
			setEmailMatch(true);
			dispatch(
				membershipReminder({
					email: data.emailAddress,
					firstName: data.firstName,
					lastName: data.lastName,
					subscriberNumber: data.subscriberNumber
				})
			);
		} else if (data.emailAddress !== data.confirmEmail) {
			setEmailMatch(false);
		}
	};
	return (
		<React.Fragment>
			<Typography
				component='h2'
				sx={{ fontSize: '2em', fontWeight: 'bold', marginBottom: '10px' }}
			>
				{t('home:welcomeHeader')}
			</Typography>
			<Typography component='p' sx={{ margin: '15px 0' }}>
				{t('home:welcomeText')}
			</Typography>
			<StyledFormBox component='section'>
				<Stack
					direction='row'
					flexWrap='wrap'
					justifyContent='space-between'
					spacing={2}
				>
					<Box sx={{ width: '45%' }}>
						<Typography
							component='p'
							sx={{ color: (theme) => theme.palette.error.main }}
						>
							* = {t('home:formElements.requiredField')}
						</Typography>
						{hasCompleted && !data?.response && (
							<Typography
								component='p'
								sx={{ color: (theme) => theme.palette.error.main }}
							>
								User does not exist
							</Typography>
						)}
						{error && (
							<Typography
								component='p'
								sx={{ color: (theme) => theme.palette.error.main }}
							>
								{error}
							</Typography>
						)}
						{!emailMatch && (
							<Typography
								component='p'
								sx={{ color: (theme) => theme.palette.error.main }}
							>
								Emails do not match
							</Typography>
						)}

						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							name='subscriberNumber'
							control={control}
							helperText={
								<Link
									onClick={() => setOpenSubNumberDialog(true)}
									sx={{ fontSize: '12px' }}
								>
									{t('home:formElements.subMemberNumber-subText')}
								</Link>
							}
							label={`${t('home:formElements.subMemberNumber')}*`}
							onChangeValidate={() => validateField('subscriberNumber')}
						/>

						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							name='firstName'
							control={control}
							label={`${t('home:formElements.firstName')}*`}
							onChangeValidate={() => validateField('firstName')}
						/>

						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							name='lastName'
							control={control}
							label={`${t('home:formElements.lastName')}*`}
							onChangeValidate={() => validateField('lastName')}
						/>

						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							name='emailAddress'
							control={control}
							label={`${t('home:formElements.emailAddress')}*`}
							onChangeValidate={() => validateField('emailAddress')}
						/>
						<TextInput
							rules={{
								required: { value: true, message: t('common:fieldRequired') }
							}}
							name='confirmEmail'
							control={control}
							label={`${t('home:formElements.confirmEmailAddress')}*`}
							onChangeValidate={() => validateField('confirmEmail')}
						/>
					</Box>
					<Box sx={{ width: '45%' }}>
						<Typography sx={{ fontSize: '0.9em' }}>
							<Trans i18nKey='home:disclosureText'>
								When you enroll in this service, we will email you a monthly
								billing reminder approximately 10 days before we automatically
								charge your credit or debit card for your membership. Please
								note that enrolling in this reminder service does not affect the
								terms of the offer(s) to which you already agreed. Even if you
								do not sign up for this reminder service, or if you do not
								receive a monthly billing reminder due to technical or other
								reasons, we will continue to charge the card we have on file as
								specified in the terms of the offer(s). Please allow 72 hours
								before we process your request to receive reminders. It is
								possible that, within the 72 hour update period or in the last
								several days, a payment has been sent to our payment processor
								regarding your membership(s). If this has occurred, you will not
								receive a membership reminder email for that charge as the
								associated payment has already been initiated. Please add{' '}
								<Link to='mailto:membership@onlinemembershipservices.com'>
									membership@onlinemembershipservices.com
								</Link>{' '}
								to your approved email sender list to prevent your reminder
								emails from going to your spam folder. If your email changes in
								the future, just resubmit your information and we&apos;ll update
								our records to reflect the change. To update your contact
								information or for other inquiries, contact our customer service
								department at:{' '}
								<Link to='mailto:membership@onlinemembershipservices.com'>
									membership@onlinemembershipservices.com
								</Link>
								.
							</Trans>
						</Typography>
						<CheckboxInput
							rules={{
								required: {
									value: true,
									message: t('home:formElements.requiredIAgree')
								}
							}}
							name='agreeCheckbox'
							label={t('home:formElements.agreementCheckboxText')}
							control={control}
						/>
					</Box>

					<Box
						width='100%'
						sx={{
							display: 'flex',
							flexDirection: 'column',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
					>
						<StyledButton
							variant='contained'
							color='success'
							size='large'
							onClick={handleSubmit(onSubmit)}
						>
							{t('common:buttons.submitRequest')}
						</StyledButton>
					</Box>
				</Stack>
			</StyledFormBox>
			<Dialog
				open={openSubNumberDialog}
				onClose={() => setOpenSubNumberDialog(false)}
				aria-labelledby='subscriber-number-help-title'
				aria-describedby='subscriber-number-help-description'
			>
				<DialogContent>
					<img src={helperImage} alt={t('home:subscriberHelperAltText')} />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenSubNumberDialog(false)}>
						{t('common:buttons.close')}
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

Home.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['home', 'common'])(Home);
