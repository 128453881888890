import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { primaryAPI } from '../../utilities/apiEndpointUtility';

import {
	membershipReminder,
	membershipReminderCompleted,
	membershipReminderFailed
} from './membershipReminderSlice';
import { handleAxiosError } from '../../utilities/errorUtility';

let subscriberRequest = {};

const membershipReminderEpic = (action$) =>
	action$.pipe(
		ofType(membershipReminder),
		mergeMap(async (action) => {
			try {
				subscriberRequest = action.payload;
				const response = await axios.post(
					`${primaryAPI()}/membership-reminder/activate`,
					subscriberRequest
				);

				return { subscriberRequest, response: response?.data };
			} catch (error) {
				handleAxiosError(error?.response?.status);
			}
		}),
		switchMap((res) => [membershipReminderCompleted(res)]),
		catchError((error, source) =>
			merge(of(membershipReminderFailed(error.message)), source)
		)
	);

export default [membershipReminderEpic];
