import { configureStore } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { catchError } from 'rxjs/operators';

// SLICES
import membershipReminderSlice from '../containers/MembershipReminder/membershipReminderSlice';
import doNotSellSlice from '../containers/DoNotSell/doNotSellSlice';

// EPICS
import membershipReminderEpic from '../containers/MembershipReminder/membershipReminderEpics';
import doNotSellEpic from '../containers/DoNotSell/doNotSellEpics';

const epicMiddleware = createEpicMiddleware();

const epics = combineEpics(...membershipReminderEpic, ...doNotSellEpic);

const rootEpic = (action$, store$, dependencies) =>
	epics(action$, store$, dependencies).pipe(
		catchError((error, source) => {
			console.error(error);
			return source;
		})
	);

export const store = configureStore({
	reducer: {
		membershipReminderSlice,
		doNotSellSlice
	},
	middleware: [epicMiddleware]
});

epicMiddleware.run(rootEpic);
