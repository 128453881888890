import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate
} from 'react-router-dom';

// PAGES
import Home from '../../pages/Home';
import DoNotSell from '../../pages/DoNotSell';
import Layout from '../Layout';
import PageNotFound from '../../pages/404';
import Overview from '../../pages/Overview';

const AppRouter = () => {
	return (
		<Router>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route path='' element={<Home />} />
					<Route path='overview' element={<Overview />} />
					<Route path='donotsell' element={<DoNotSell />} />
					<Route path='home' element={<Navigate to='/' />} />
					<Route path='*' element={<PageNotFound />} />
				</Route>
			</Routes>
		</Router>
	);
};

export default AppRouter;
