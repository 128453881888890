import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

const CheckboxInput = ({ name, control, label, rules }) => {
	return (
		<FormControl size={'small'} variant={'outlined'}>
			<FormControlLabel
				sx={{ flexWrap: 'wrap' }}
				control={
					<Controller
						rules={{ ...rules }}
						name={name}
						render={({ field: { onChange, value }, fieldState: { error } }) => {
							return (
								<>
									{error && (
										<Typography
											component='p'
											sx={{
												color: (theme) => theme.palette.error.main,
												width: '100%'
											}}
										>
											{error.message}
										</Typography>
									)}
									<Checkbox
										value='check'
										checked={!!value}
										onChange={(e) => {
											onChange(e.target.checked);
										}}
									/>
								</>
							);
						}}
						control={control}
					/>
				}
				label={
					<Typography component='span' sx={{ flex: 1 }}>
						{label}
					</Typography>
				}
			/>
		</FormControl>
	);
};

CheckboxInput.propTypes = {
	label: PropTypes.string,
	setValue: PropTypes.func,
	control: PropTypes.any,
	name: PropTypes.string,
	rules: PropTypes.object,
	onChangeValidate: PropTypes.func
};

export default CheckboxInput;
